var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_preferences") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": _vm.activeTabPane,
                    "active-key": _vm.activeTabPane
                  },
                  on: { change: _vm.handleChangeMenu }
                },
                _vm._l(_vm.columnTabPane, function(tab) {
                  return _c("a-tab-pane", {
                    key: tab.key,
                    attrs: { tab: _vm.$t(tab.label) }
                  })
                }),
                1
              ),
              _c(
                "transition",
                { attrs: { name: "component-fade", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _c(
                        _vm.dynamicComp,
                        _vm._g(
                          _vm._b(
                            { tag: "component" },
                            "component",
                            _vm.compProps,
                            false
                          ),
                          _vm.compEvt
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { align: "end" } },
            [
              _vm.hasPrivilegeCreate
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "save",
                        type: "primary",
                        loading: _vm.loadingSave
                      },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }