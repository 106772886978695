













































import Vue from "vue";
import { IPrefFeature } from "./PreferencesFeature.vue";
import { IPrefSales } from "./PreferencesSales.vue";
import { IPrefInventory } from "./PreferencesInventory.vue";
import { IPrefAccountSetup } from "./PreferencesAccountSetup.vue";
import { IPrefPurchases } from "./PreferencesPurchases.vue";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { RequestUpdatePreference, ResponsePreference } from "@/models/interface/settings.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import localStorageService from "@/services/localStorage.service";
import { PREFERENCE_FEATURE_KEY } from "@/models/enums/preference.enum";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { APP_DECIMAL_PLACES } from "@/models/enums/global.enum";
import { mapGetters, mapMutations } from "vuex";

export enum COMPONENT_NAME {
  PREF_FEATURE = "PREFRENCES_FEATURE",
  PREF_SALES = "PREFERENCES_SALES",
  PREF_PURCHASES = "PREFERENCES_PURCHASES",
  PREF_INVENTORY = "PREFERENCES_INVENTORY",
  PREF_ACCOUNT_SETUP = "PREFERENCES_ACCOUNT_SETUP"
}

export default Vue.extend({
  name: "Preferences",
  components: {
    "Preferences-Feature": () => import(/*webpackPrefetch: true */"./PreferencesFeature.vue"),
    "Preferences-Sales": () => import(/*webpackPrefetch: true */"./PreferencesSales.vue"),
    "Preferences-Inventory": () => import(/*webpackPrefetch: true */"./PreferencesInventory.vue"),
    "Preferences-Account-Setup": () => import(/*webpackPrefetch: true */"./PreferencesAccountSetup.vue"),
    "Preferences-Purchases": () => import(/*webpackPrefetch: true */"./PreferencesPurchases.vue")
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    const columnTabPane: {
      key: string,
      label: string,
      component: "Preferences-Feature" | "Preferences-Sales" | "Preferences-Inventory" | "Preferences-Account-Setup" | "Preferences-Purchases"
    }[] = [
      {
        key: "feature",
        label: "lbl_feature",
        component: "Preferences-Feature"
      },
      {
        key: "sales",
        label: "lbl_sales",
        component: "Preferences-Sales"
      },
      {
        key: "purchases",
        label: "lbl_purchases",
        component: "Preferences-Purchases"
      },
      {
        key: "inventory",
        label: "lbl_inventory",
        component: "Preferences-Inventory"
      },
      {
        key: "account_setup",
        label: "lbl_account_setup",
        component: "Preferences-Account-Setup"
      },
    ];
    return {
      firstLoadComponentTab: {
        feature: false as boolean,
        sales: false as boolean,
        purchase: false as boolean,
        inventory: false as boolean,
        accountSetup: false as boolean,
      },
      listIdCoa: [] as string[],
      dataFeatureDetail: [] as ResponsePreference[],
      dataSalesDetail: [] as ResponsePreference[],
      dataPurchaseDetail: [] as ResponsePreference[],
      dataInventoryDetail: [] as ResponsePreference[],
      dataAccountSetupDetail: [] as ResponsePreference[],
      activeTabPane: "feature" as "feature" | "sales" | "purchases" | "inventory" | "account_setup",
      columnTabPane,
      loadingSave: false as boolean,
      prefFeature: {
        basic: [] as string[],
        additional: [] as string[],
        currency: {
          feature_based_currency: "" as string,
          feature_decimal_format: "" as string,
          feature_decimal_point: "" as string,
          feature_discount_before_after_tax: "" as string
        },
        freight: "",
      } as IPrefFeature,
      prefSales: {
        sales_top: "" as string,
        basic: [] as string[],
        sales_commision_counting_from: "" as string
      } as IPrefSales,
      prefPurchase: {
        purchase_top: "" as string,
        basic: [] as string[],
        purchase_import_cost_ppn: "",
        purchase_import_cost_pph_22: "",
      } as IPrefPurchases,
      prefInventory: {
        inventory_costing_method: "" as string,
        basic: [] as string[],
      } as IPrefInventory,
      prefAccountSetup: {
        balanceSheet: {
          account_setup_opening_balance_equity: "" as string,
          account_setup_retained_earning: "" as string,
          account_setup_exchange_rate_variance_gain: "" as string,
          account_setup_exchange_rate_variance_loss: "" as string
        },
        salesPurchase: {
          account_setup_sales_revenue: "" as string,
          account_setup_sales_discount_invoice_line: "" as string,
          account_setup_sales_discount_invoice_total: "" as string,
          account_setup_sales_return: "" as string,
          account_setup_sales_delivery: "" as string,
          account_setup_sales_bad_debt: "" as string,
          account_setup_unearned_revenue: "" as string,
          account_setup_unbilled_sales: "" as string,
          account_setup_unbilled_receivables: "" as string,
          account_setup_purchase_cogs: "" as string,
          account_setup_purchase_shipping: "" as string,
          account_setup_purchase_return: "" as string,
          account_setup_purchase_bad_debt: "" as string,
          account_setup_unbilled_payables: "" as string,
          account_setup_rounding_account_gain: "" as string,
          account_setup_rounding_account_loss: "" as string,
          account_setup_purchase_price_variance: "" as string,
          account_setup_purchase_discount: "" as string
        },
        arap: {
          account_setup_account_receiviables: "" as string,
          account_setup_account_payables: "" as string,
          account_setup_invoice_price_variance: "" as string,
          account_setup_prepayment_ap: "" as string,
          account_setup_prepayment_ar: "" as string,
        },
        inventory: {
          account_setup_inventory: "" as string,
          account_setup_inventory_non_stock: "" as string,
          account_setup_inventory_waste: "" as string,
          account_setup_stock_adjustment: "" as string,
          account_setup_stock_variance: "" as string
        },
        tax: {
          account_setup_vat_in: "" as string,
          account_setup_vat_out: "" as string
        }
      } as IPrefAccountSetup
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "preferences" && x.privilege.update && x.privilege.create);
    },
    dynamicComp(): "Preferences-Feature" | "Preferences-Sales" | "Preferences-Inventory" | "Preferences-Account-Setup" | "Preferences-Purchases" | string {
      return this.columnTabPane.find(el => el.key === this.activeTabPane)?.component || "";
    },
    compProps() {
      const returnValue: {
        firstLoad: boolean,
        dataFirstLoad: IPrefFeature | IPrefSales | IPrefPurchases | IPrefInventory | IPrefAccountSetup | Record<string, unknown>
      } = {
        firstLoad: false,
        dataFirstLoad: {}
      };
      if (this.activeTabPane === "feature") {
        returnValue.firstLoad = this.firstLoadComponentTab.feature;
        returnValue.dataFirstLoad = this.prefFeature;
      } else if (this.activeTabPane === "sales") {
        returnValue.firstLoad = this.firstLoadComponentTab.sales;
        returnValue.dataFirstLoad = this.prefSales;
      } else if (this.activeTabPane === "purchases") {
        returnValue.firstLoad = this.firstLoadComponentTab.purchase;
        returnValue.dataFirstLoad = this.prefPurchase;
      } else if (this.activeTabPane === "inventory") {
        returnValue.firstLoad = this.firstLoadComponentTab.inventory;
        returnValue.dataFirstLoad = this.prefInventory;
      } else if (this.activeTabPane === "account_setup") {
        returnValue.firstLoad = this.firstLoadComponentTab.accountSetup;
        returnValue.dataFirstLoad = this.prefAccountSetup;
      }
      return returnValue;
    },
    compEvt() {
      return {
        onChangeLoad: this.onChangeLoad,
        onChange: this.onFeatureChange
      } as {
        onChangeLoad: ({name: string, val: boolean}) => void,
        onChange: ({name: string, val: any}) => void
      };
    }
  },
  created () {
    const paramsFeature:RequestQueryParamsModel = {
      name: "key~feature_*"
    };
    const paramsSales:RequestQueryParamsModel = {
      name: "key~sales_*"
    };
    const paramsPurchases:RequestQueryParamsModel = {
      name: "key~purchase_*"
    };
    const paramsInventory:RequestQueryParamsModel = {
      name: "key~inventory_*"
    };
    const paramsAccountSetup:RequestQueryParamsModel = {
      name: "key~account_setup_*"
    };
    const promiseFeature:Promise<ResponsePreference[]> = settingsServices.getPreference(paramsFeature);
    const promiseSales:Promise<ResponsePreference[]> = settingsServices.getPreference(paramsSales);
    const promisePurchases:Promise<ResponsePreference[]> = settingsServices.getPreference(paramsPurchases);
    const promiseInventory:Promise<ResponsePreference[]> = settingsServices.getPreference(paramsInventory);
    const promiseAccountSetup:Promise<ResponsePreference[]> = settingsServices.getPreference(paramsAccountSetup);

    Promise.all([promiseFeature, promiseSales, promisePurchases, promiseInventory, promiseAccountSetup])
    .then((response) => {
      response.map((dataMap, index) => {
        switch(index) {
          case 0:
            this.dataFeatureDetail = dataMap;
            this.handleSetFeature(dataMap);
            break;
          case 1:
            this.dataSalesDetail = dataMap;
            this.handleSetSales(dataMap);
            break;
          case 2:
            this.dataPurchaseDetail = dataMap;
            this.handleSetPurchase(dataMap);
            break;
          case 3:
            this.dataInventoryDetail = dataMap;
            this.handleSetInventory(dataMap);
            break;
          case 4:
            this.dataAccountSetupDetail = dataMap;
            this.handleSetAccountSetup(dataMap);
            break;
          default:
            break;
        }
      });
    });
  },
  methods: {
    ...mapMutations({
      setAppDecimalPlaces: "preferenceStore/SET_DECIMAL_PLACES",
    }),
    onChangeLoad (param: { name: string, val: boolean }) {
      switch(param.name) {
        case COMPONENT_NAME.PREF_FEATURE:
          this.firstLoadComponentTab.feature = param.val;
          break;
        case COMPONENT_NAME.PREF_SALES:
          this.firstLoadComponentTab.sales = param.val;
          break;
        case COMPONENT_NAME.PREF_PURCHASES:
          this.firstLoadComponentTab.purchase = param.val;
          break;
        case COMPONENT_NAME.PREF_INVENTORY:
          this.firstLoadComponentTab.inventory = param.val;
          break;
        case COMPONENT_NAME.PREF_ACCOUNT_SETUP:
          this.firstLoadComponentTab.accountSetup = param.val;
          break;
        default:
          break;
      }
    },
    handleChangeMenu (value) {
      this.activeTabPane = value;
    },
    onFeatureChange(param: {name: string, val: any}): void {
      switch (param.name) {
        case COMPONENT_NAME.PREF_FEATURE:
          this.prefFeature = param.val;
          break;
        case COMPONENT_NAME.PREF_SALES:
          this.prefSales = param.val;
          break;
        case COMPONENT_NAME.PREF_PURCHASES:
          this.prefPurchase = param.val;
          break;
        case COMPONENT_NAME.PREF_INVENTORY:
          this.prefInventory = param.val;
          break;
        case COMPONENT_NAME.PREF_ACCOUNT_SETUP:
          this.prefAccountSetup = param.val;
          break;
        default:
          break;
      }
    },
    handleSetFeature (dataFeature) {
      dataFeature.forEach((dataForEach) => {
        if(dataForEach.value === "true") {
          this.prefFeature.basic.push(dataForEach.key);
        }
        if (dataForEach.key === "feature_freight") {
          this.prefFeature.freight = dataForEach.value;
        } else if(dataForEach.key === "feature_decimal_format") {
          this.prefFeature.currency.feature_decimal_format = dataForEach.value;
        } else if (dataForEach.key === "feature_decimal_point") {
          this.prefFeature.currency.feature_decimal_point = dataForEach.value;
        } else if (dataForEach.key === "feature_discount_before_after_tax") {
          this.prefFeature.currency.feature_discount_before_after_tax = dataForEach.value;
        } else  if (dataForEach.key === "feature_based_currency") {
          this.prefFeature.currency.feature_based_currency = dataForEach.value;
        }
      });
    },
    handleSetSales (dataSales) {
      dataSales.forEach((dataForEach) => {
        if(dataForEach.value === "true") {
          this.prefSales.basic.push(dataForEach.key);
        }
        if(dataForEach.key === "sales_top") {
          this.prefSales.sales_top = dataForEach.value;
        } else if (dataForEach.key === "sales_commision_counting_from") {
          this.prefSales.sales_commision_counting_from = dataForEach.value;
        }
      });
    },
    handleSetPurchase (dataPurchase) {
      dataPurchase.forEach((dataForEach) => {
        if(dataForEach.value === "true") {
          this.prefPurchase.basic.push(dataForEach.key);
        }
        if(dataForEach.key === "purchase_top") {
          this.prefPurchase.purchase_top = dataForEach.value;
        }
        if(dataForEach.key === "purchase_import_cost_pph_22") {
          this.prefPurchase.purchase_import_cost_pph_22 = dataForEach.value;
        }
        if(dataForEach.key === "purchase_import_cost_ppn") {
          this.prefPurchase.purchase_import_cost_ppn = dataForEach.value;
        }
      });
    },
    handleSetInventory (dataInventory) {
      dataInventory.forEach((dataForEach) => {
        if(dataForEach.value === "true") {
          this.prefInventory.basic.push(dataForEach.key);
        }
        if(dataForEach.key === "inventory_costing_method") {
          this.prefInventory.inventory_costing_method = dataForEach.value;
        }
      });
    },
    handleSetAccountSetup (dataAccountSetup) {
      dataAccountSetup.forEach((dataForEach) => {
        for (const key in this.prefAccountSetup) {
          if (typeof this.prefAccountSetup[key] === "object" && !Array.isArray(this.prefAccountSetup[key]) && this.prefAccountSetup[key] !== null) {
            const element = this.prefAccountSetup[key];
            for (const keyObject in element) {
              if(keyObject === dataForEach.key) {
                this.prefAccountSetup[key][keyObject] = dataForEach.value;
                this.listIdCoa = [...this.listIdCoa, dataForEach.value];
              }
            }
          }
        }
      });
    },
    handleSubmitFeature (): RequestUpdatePreference[] {
      const temp:RequestUpdatePreference[] = [];
      this.dataFeatureDetail = this.dataFeatureDetail.map(dataMap => {
        if(dataMap.value === "true" || dataMap.value === "false") {
          if(this.prefFeature.basic.includes(dataMap.key)) {
            temp.push({ key: dataMap.key, value: "true", secureId: dataMap.id });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({ key: dataMap.key, value: "false", secureId: dataMap.id });
            return { ...dataMap, value: "false" };
          }
        } else if(dataMap.key === "feature_decimal_format") {
          temp.push({ key: dataMap.key, value: this.prefFeature.currency.feature_decimal_format, secureId: dataMap.id });
          return { ...dataMap, value: this.prefFeature.currency.feature_decimal_format };
        } else if(dataMap.key === "feature_discount_before_after_tax") {
          temp.push({ key: dataMap.key, value: this.prefFeature.currency.feature_discount_before_after_tax, secureId: dataMap.id });
          return { ...dataMap, value: this.prefFeature.currency.feature_discount_before_after_tax };
        } else if (dataMap.key === "feature_based_currency") {
          temp.push({ key: dataMap.key, value: this.prefFeature.currency.feature_based_currency, secureId: dataMap.id });
          return { ...dataMap, value: this.prefFeature.currency.feature_based_currency };
        }else if (dataMap.key === "feature_freight") {
          temp.push({ key: dataMap.key, value: this.prefFeature.freight, secureId: dataMap.id });
          return { ...dataMap, value: this.prefFeature.freight };
        } else {
          // this key surely decimal option
          temp.push({ key: dataMap.key, value: this.prefFeature.currency.feature_decimal_point, secureId: dataMap.id });
          return { ...dataMap, value: this.prefFeature.currency.feature_decimal_point };
        }
      });

      return temp;
    },
    handleSubmitSales (): RequestUpdatePreference[] {
      const temp:RequestUpdatePreference[] = [];
      this.dataSalesDetail = this.dataSalesDetail.map(dataMap => {
        if(dataMap.value === "true" || dataMap.value === "false") {
          if(this.prefSales.basic.includes(dataMap.key)) {
            temp.push({ key: dataMap.key, value: "true", secureId: dataMap.id });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({ key: dataMap.key, value: "false", secureId: dataMap.id });
            return { ...dataMap, value: "false" };
          }
        } else if(dataMap.key === "sales_top") {
          temp.push({ key: dataMap.key, value: this.prefSales.sales_top, secureId: dataMap.id });
          return { ...dataMap, value: this.prefSales.sales_top };
        } else {
          // this key surely sales_commision_counting_from
          temp.push({ key: dataMap.key, value: this.prefSales.sales_commision_counting_from, secureId: dataMap.id });
          return { ...dataMap, value: this.prefSales.sales_commision_counting_from };
        }
      });

      return temp;
    },
    handleSubmitPurchase (): RequestUpdatePreference[] {
      const temp:RequestUpdatePreference[] = [];
      const { dataPurchaseDetail } = this;
      this.dataPurchaseDetail = dataPurchaseDetail.map(dataMap => {
        if(dataMap.value === "true" || dataMap.value === "false") {
          if(this.prefPurchase.basic.includes(dataMap.key)) {
            temp.push({ key: dataMap.key, value: "true", secureId: dataMap.id });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({ key: dataMap.key, value: "false", secureId: dataMap.id });
            return { ...dataMap, value: "false" };
          }
        } else if (dataMap.key === "purchase_import_cost_ppn") {
          temp.push({ key: dataMap.key, value: this.prefPurchase.purchase_import_cost_ppn, secureId: dataMap.id });
          return { ...dataMap, value: this.prefPurchase.purchase_import_cost_ppn };
        } else if (dataMap.key === "purchase_import_cost_pph_22") {
          temp.push({ key: dataMap.key, value: this.prefPurchase.purchase_import_cost_pph_22, secureId: dataMap.id });
          return { ...dataMap, value: this.prefPurchase.purchase_import_cost_pph_22 };
        } else {
          // this key surely purchase_top
          temp.push({ key: dataMap.key, value: this.prefPurchase.purchase_top, secureId: dataMap.id });
          return { ...dataMap, value: this.prefPurchase.purchase_top };
        }
      });

      return temp;
    },
    handleSubmitInventory (): RequestUpdatePreference[] {
      const temp:RequestUpdatePreference[] = [];
      this.dataInventoryDetail = this.dataInventoryDetail.map(dataMap => {
        if(dataMap.value === "true" || dataMap.value === "false") {
          if(this.prefInventory.basic.includes(dataMap.key)) {
            temp.push({ key: dataMap.key, value: "true", secureId: dataMap.id });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({ key: dataMap.key, value: "false", secureId: dataMap.id });
            return { ...dataMap, value: "false" };
          }
        }else {
          // this key surely inventory_costing_method
          temp.push({ key: dataMap.key, value: this.prefInventory.inventory_costing_method, secureId: dataMap.id });
          return { ...dataMap, value: this.prefInventory.inventory_costing_method };
        }
      });

      return temp;
    },
    handleSubmitAccountSetup (): RequestUpdatePreference[] {
      const temp:RequestUpdatePreference[] = [];
      this.dataAccountSetupDetail = this.dataAccountSetupDetail.map(dataMap => {
        const data:ResponsePreference = {
          createdDate:"",
          id:"",
          key:"",
          modifiedDate:"",
          value:""
        };
        for (const key in this.prefAccountSetup) {
          if (typeof this.prefAccountSetup[key] === "object" && !Array.isArray(this.prefAccountSetup[key]) && this.prefAccountSetup[key] !== null) {
            const element = this.prefAccountSetup[key];
            for (const keyObject in element) {
              if(keyObject === dataMap.key) {
                data.id = dataMap.id;
                data.createdDate = dataMap.createdDate;
                data.key = dataMap.key;
                data.modifiedDate = dataMap.modifiedDate;
                data.value = element[keyObject];
                temp.push({ value: element[keyObject], key: dataMap.key, secureId: dataMap.id });
              }
            }
          }
        }
        return data;
      });

      return temp;
    },
    onSubmit(): void {
      const payload = [
        ...this.handleSubmitFeature(),
        ...this.handleSubmitSales(),
        ...this.handleSubmitPurchase(),
        ...this.handleSubmitInventory(),
        ...this.handleSubmitAccountSetup()
      ] as RequestUpdatePreference[];

      this.loadingSave = true;
      settingsServices.updatePreference(payload)
        .then(() => {
          this.showUpdateSuccessMesssage();
        })
        .then(() => {
          settingsServices.getPreference({}).then((response) => {
            this.$store.commit("preferenceStore/SET_APP_PREFERENCE", response);
            const featureDp = response.find(x => x.key === PREFERENCE_FEATURE_KEY.FEATURE_DECIMAL_POINT)?.value || DECIMAL_PLACES_CURRENCY;
            this.setAppDecimalPlaces(featureDp);
            localStorageService.save(APP_DECIMAL_PLACES.DP, featureDp.toString());
          });
        })
        .catch(() => this.showUpdateFailedMesssage())
        .finally(() => this.loadingSave = false);
    }
  }
});
